<!--

  Legacy solution for renewals

-->
<template>
  <div class="container">

    <b-modal
      size="lg"
      :title="item && item.shop_item_id ? $t('SHOP_ITEM.UPDATING') : $t('SHOP_ITEM.CREATING')"
      ref="edit-form"
      hide-footer
      >

      <!-- Edit form -->
      <ShopItemEditor
        :item="item"
        :creating="creating"
        :templates="templates"
        :memberTypeOptions="memberTypeOptions"
        :shop_items="shop_items"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="shop_item_created"
        @updated="shop_item_updated"
      />


    </b-modal>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import ShopItemEditor from '@/view/pages/ml/shopitem/ShopItemEditor.vue';


export default {

  name: 'ShopItemEditorModal',

  props: ['item','memberTypeOptions','templates','is_ticket','shop_item_id','shop_items','isTHS'],
  emits: ['created', 'updated'],
  mixins: [ toasts ],

  components: {
    ShopItemEditor
  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    show() {
      this.$refs['edit-form'].show();
    },

    hide() {
      this.$refs['edit-form'].hide();
    },

    shop_item_created(item) {
      this.$emit('created', item);

      this.hide();

      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.CREATED'));
    },


    shop_item_updated(item) {
      this.$emit('updated', item);

      this.hide();

      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.UPDATED'));
    }
  },

  mounted() {


  },

  data() {
    return {

      creating: true,

    };
  }
};

</script>


<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
