<template>
  <div id="page-shop_items">
    <h5 class="m-4">{{ $t('AUTH.MENU.SHOPITEM') }}</h5>

    <!--<h4>{{ $t('AUTH.MENU.SHOPITEM') }}</h4>-->

    <b-card>
      
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{$t('PAGES.SHOPITEM.SHOPITEMS')}}</v-tab>
        <v-tab>{{$t('PAGES.CAMPAIGNS.CAMPAIGNS')}}</v-tab>

        <v-tab-item>
          <ShopItemTab />
        </v-tab-item>
        <v-tab-item>
          <CampaignsTab />
        </v-tab-item>
      </v-tabs>
    </b-card>

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ShopItemTab from '@/view/pages/ml/shopitem/ShopItemTab.vue';
import CampaignsTab from '@/view/pages/ml/shopitem/CampaignsTab.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'shop_items',
  components: {
    ShopItemTab,
    CampaignsTab
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  watch: {

  },
  methods: {

  }
};
</script>
