<template>
    <b-card title="" class="mb-2 edit-campaign-container" ref="edit-campaign" id="edit-campaign" hide-footer>
      <div>

        <required-fields-info/>

        <b-form ref="edit-form" @submit.prevent="on_submit" class="mt-8">
          <b-form-group id="group-id" label="ID" label-for="input-id">
            <b-form-input
              id="input-id"
              v-model="local_campaign.id"
              type="text"
              disabled>
            </b-form-input>
          </b-form-group>

          <b-form-group id="group-name">
            <tip-label class="mb-2" for="field-name" :tip="$t('PAGES.CAMPAIGNS.NAME_TIP')" asterix>{{$t('PAGES.CAMPAIGNS.NAME')}}</tip-label>
            <b-form-input
              id="field-name"
              v-model="local_campaign.name"
              type="text"
              :state="name_state"
            />
            <b-form-invalid-feedback>{{$t('PAGES.CAMPAIGNS.INVALID_NAME')}}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="group-descr">
            <tip-label class="mb-2" for="field-descr" :tip="$t('PAGES.CAMPAIGNS.DESCR_TIP')" asterix>{{$t('PAGES.CAMPAIGNS.DESCR')}}</tip-label>
            <b-form-textarea
              id="field-descr"
              v-model="local_campaign.descr"
              size="sm"
              :placeholder="$t('PAGES.CAMPAIGNS.DESCR')"
              :state="descr_state"
            />
            <b-form-invalid-feedback>{{$t('PAGES.CAMPAIGNS.INVALID_DESCR')}}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="group-max_uses">
            <tip-label class="mb-2" for="field-max_uses" :tip="$t('PAGES.CAMPAIGNS.MAX_USES_TIP')" asterix>{{$t('PAGES.CAMPAIGNS.MAX_USES')}}</tip-label>
            <b-form-input
              id="field-max_uses"
              v-model="local_campaign.max_uses"
              type="text"
              v-numeric
              :state="max_uses_state"
            />
            <b-form-invalid-feedback>{{$t('PAGES.CAMPAIGNS.INVALID_MAX_USES')}}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="group-from_date">
            <tip-label class="mb-2" for="input-from_date" :tip="$t('PAGES.CAMPAIGNS.FROM_DATE_TIP')" asterix>{{$t('PAGES.CAMPAIGNS.FROM_DATE')}}</tip-label>
              <memlist-date-picker
                id="field-from_date"
                v-model="local_campaign.from_date"
                :state="from_date_state"
              />
              <b-form-valid-feedback>{{$t('PAGES.CAMPAIGNS.VALID_DATE')}}</b-form-valid-feedback>
              <b-form-invalid-feedback>{{$t('PAGES.CAMPAIGNS.INVALID_DATE')}}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="group-to_date">
            <tip-label class="mb-2" for="field-to_date" :tip="$t('PAGES.CAMPAIGNS.TO_DATE_TIP')" asterix>{{$t('PAGES.CAMPAIGNS.TO_DATE')}}</tip-label>
            <memlist-date-picker
              id="field-from_date"
              v-model="local_campaign.to_date"
              :state="to_date_state"
            />
            <b-form-valid-feedback>{{$t('PAGES.CAMPAIGNS.VALID_DATE')}}</b-form-valid-feedback>
            <b-form-invalid-feedback>{{$t('PAGES.CAMPAIGNS.INVALID_DATE')}}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="group-percent">
            <tip-label class="mb-2" for="field-percent" :tip="$t('PAGES.CAMPAIGNS.PERCENT_DISCOUNT_TIP')" asterix>{{$t('PAGES.CAMPAIGNS.PERCENT_DISCOUNT')}}</tip-label>
            <b-form-input
              id="field-percent"
              v-model="local_campaign.percent"
              type="text"
              v-numeric
              :state="percent_state"
            />
            <b-form-invalid-feedback>{{$t('PAGES.CAMPAIGNS.INVALID_PERCENT')}}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="group-use_code">
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input id="field-use_code" type="checkbox" name="" v-model="local_campaign.use_code" disabled/>
                <span></span>
              </label>
              <tip-label class="ml-3" for="field-use_code" :tip="$t('PAGES.CAMPAIGNS.USE_CODE_TIP')">{{$t('PAGES.CAMPAIGNS.USE_CODE')}}</tip-label>
            </div>
          </b-form-group>

          <b-form-group v-if="local_campaign.use_code" id="group-code">
            <tip-label class="mb-2" for="field-code" :tip="$t('PAGES.CAMPAIGNS.CODE_TIP')" asterix>{{$t('PAGES.CAMPAIGNS.CODE')}}</tip-label>
            <b-form-input
              id="field-code"
              v-model="local_campaign.code"
              trim
              :state="code_state"
            />
            <b-form-invalid-feedback>{{$t('PAGES.CAMPAIGNS.INVALID_CODE')}}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group  id="group-active">
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input id="input-active" type="checkbox" name="" v-model="local_campaign.active"/>
                <span></span>
              </label>
              <tip-label class="ml-3" for="input-active" :tip="$t('PAGES.CAMPAIGNS.ACTIVE_TIP')">{{$t('PAGES.CAMPAIGNS.ACTIVE')}}</tip-label>
            </div>
          </b-form-group>

          <div v-if="!local_campaign.use_code" class="alert alert-custom alert-notice alert-light-primary fade show mt-4" role="alert">
            <div class="alert-icon"><i class="flaticon-warning"></i></div>
            <div class="alert-text">{{ $t('PAGES.CAMPAIGNS.ACTIVE_WARNING') }}</div>
          </div>

          <b-button class="mt-8" variant="primary" type="submit">{{$t('COMMON.SAVE')}}</b-button>
          <b-button class="mt-8 ml-3" variant="outline-secondary" @click="$emit('close_clicked')" >{{$t('COMMON.CLOSE')}}</b-button>

        </b-form>

      </div>
    </b-card>

</template>

<style lang="scss">
</style>

<script>
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { validate_state } from '@/core/services/validations.service';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minLength, minValue, required } from '@vuelidate/validators';


export default {
  name: 'CampaignEdit',
  components: { TipLabel, RequiredFieldsInfo },
  mixins: [ toasts ],
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      local_campaign: {
        name: { required },
        descr: { required },
        max_uses: { required, minValue: minValue(1) },
        from_date: { required },
        to_date: { required },
        percent: { required, minValue: minValue(1), maxValue: maxValue(100)},
        code: { required, minLength: minLength(4) },
      }
    }
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
    name_state() {
      return validate_state({ dirty: this.v$.local_campaign.name.$dirty, error: this.v$.local_campaign.name.$error })
    },
    descr_state() {
      return validate_state({ dirty: this.v$.local_campaign.descr.$dirty, error: this.v$.local_campaign.descr.$error })
    },
    max_uses_state() {
      return validate_state({ dirty: this.v$.local_campaign.max_uses.$dirty, error: this.v$.local_campaign.max_uses.$error })
    },
    from_date_state() {
      return validate_state({ dirty: this.v$.local_campaign.from_date.$dirty, error: this.v$.local_campaign.from_date.$error })
    },
    to_date_state() {
      return validate_state({ dirty: this.v$.local_campaign.to_date.$dirty, error: this.v$.local_campaign.to_date.$error })
    },
    percent_state() {
      return validate_state({ dirty: this.v$.local_campaign.percent.$dirty, error: this.v$.local_campaign.percent.$error })
    },
    code_state() {
      return validate_state({ dirty: this.v$.local_campaign.use_code.$dirty, error: this.v$.local_campaign.use_code.$error })
    },
  },

  directives: {
    numeric: {
      inserted: (el) => {
        el.oninput = (ev) => {
          const value = parseInt(ev.target.value, 10);
          el.value = isNaN(value) ? '' : value;
        };
      }
    }
  },

  mounted() {
    if (this.campaign) {
      this.local_campaign = { ...this.campaign };
    }
  },

  data() {
    return {
      local_campaign: {},
    };
  },

  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  emits: ['create:campaign', 'update:campaign', 'close_clicked'],
  watch: {
    campaign: {
      deep: true,
      handler(val) {
        this.local_campaign = { ...val };
      }
    }
  },

  methods: {
    async validate() {
      await this.v$.$validate();
    },

    async on_submit() {
      const is_valid = await this.validate();

      if (!is_valid) {
        this.v$.$touch();
        return;
      }

      this.$emit(this.local_campaign.id ? 'update:campaign' : 'create:campaign', this.local_campaign);
    },
  }
};

</script>

