<template>

  <div class="card card-custom card-stretch gutter-b">

    <v-col class="px-0 py-0 my-4 text-right">
      <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="create_campaign_clicked">
        <i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.CAMPAIGNS.NEW')}}
      </a>
    </v-col>

    <v-row class="mx-0 my-4">
      <v-spacer/>
      <b-form-select class="pagination-select" v-model="perPage" :options="options"> </b-form-select>
      <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          aria-controls="campaigns-table"
          first-number
          last-number
        ></b-pagination>
    </v-row>


    <div class="card-body pt-0 px-0">
      <b-table
        id="campaigns-table"
        class="mh-100 table-striped"
        :filter="filter"
        :fields="headers"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        head-variant="light"
        sticky-header
        hover
      >
        <template #head(active)="data">
          <tip-label :tip="$t('PAGES.CAMPAIGNS.ACTIVE_HEADER_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(name)="data">
          <tip-label :tip="$t('PAGES.CAMPAIGNS.NAME_HEADER_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(code)="data">
          <tip-label :tip="$t('PAGES.CAMPAIGNS.CODE_HEADER_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(percent)="data">
          <tip-label :tip="$t('PAGES.CAMPAIGNS.PERCENT_DISCOUNT_HEADER_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(amount)="data">
          <tip-label :tip="$t('PAGES.CAMPAIGNS.AMOUNT_HEADER_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(from_date)="data">
          <tip-label :tip="$t('PAGES.CAMPAIGNS.FROM_DATE_HEADER_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(to_date)="data">
          <tip-label :tip="$t('PAGES.CAMPAIGNS.TO_DATE_HEADER_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #cell(name)="data">
          <div :title='data.item.name' class='d-none d-sm-block'> {{ data.item.name }} </div>
          <div :title='data.item.name' class='d-block d-sm-none'> {{ maxLengthOfCell(data.item.name, 14) }} </div>
        </template>

        <template #cell(actions)="data">
          <div class='d-flex justify-content-end'>
            <a href="#" class="btn btn-icon btn-light btn-sm mx-3" @click="select_campaign_clicked(data.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary" v-b-tooltip="{ title: $t('PAGES.CAMPAIGNS.EDIT_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500  }">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm mr-4" @click="delete_campaign_clicked(data.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary" v-b-tooltip="{ title: $t('PAGES.CAMPAIGNS.DELETE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500  }">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>

    </div>
    <!--end::Body-->
  </div>

</template>
<style lang="scss" scoped>
.pagination-select {
  height: 32px;
  padding: 0.60rem 2rem 0.65rem 1rem;
  margin-right: 12px;
  max-width: 100px;
}

</style>
<script>
import dayjs from 'dayjs';
import TipLabel from '@/view/components/TipLabel.vue';


export default {
  name: "campaigns-table",
  props: ["items","currentCompanyId"],
  emits: ['create_campaign_clicked', 'select_campaign_clicked', 'delete_campaign_clicked'],
  components: {
    
    TipLabel
  },
  methods: {
    create_campaign_clicked() {
      this.$emit('create_campaign_clicked');
    },
    select_campaign_clicked(id) {
      this.$emit('select_campaign_clicked', id);
    },
    delete_campaign_clicked(id) {
      this.$emit('delete_campaign_clicked', id);
    },
    maxLengthOfCell(str, length) {
      return str.length > length ? str.substring(0, length) + '...' : str;
    }
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      options: [25, 50, 100],
      headers: [
        {
          key: 'active',
          show: true,
          sortable: false,
          label: this.$t('PAGES.CAMPAIGNS.ACTIVE'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
          formatter: (_, __, item) => {
            return item.active ? this.$t('COMMON.YES') : this.$t('COMMON.NO')
          },
        },
        {
          key: 'name',
          show: true,
          sortable: true,
          label: this.$t('PAGES.CAMPAIGNS.NAME'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'code',
          show: true,
          sortable: false,
          label: this.$t('PAGES.CAMPAIGNS.CODE'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
        },
        {
          key: 'percent',
          show: true,
          sortable: false,
          label: this.$t('PAGES.CAMPAIGNS.PERCENT_DISCOUNT'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
          formatter: (_, __, item) => {
            return Math.round(item.percent * 100);
          }
        },
        {
          key: 'amount',
          show: true,
          sortable: false,
          label: this.$t('PAGES.CAMPAIGNS.AMOUNT'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
          formatter: (_, __, item) => {
            return item.num_uses + ' / ' + item.max_uses;
          }
        },
        {
          key: 'from_date',
          label: this.$t('PAGES.CAMPAIGNS.FROM_DATE'),
          sortable: true,
          sortByFormatted: true,
          formatter: (_, __, item) => {
            return dayjs(item.from_date).format('YYYY-MM-DD');
          },
          thClass: 'td-short4',
          tdClass: 'td-short4 vertical-center'
        },
        {
          key: 'to_date',
          label: this.$t('PAGES.CAMPAIGNS.TO_DATE'),
          sortable: true,
          sortByFormatted: true,
          formatter: (_, __, item) => {
            return dayjs(item.to_date).format('YYYY-MM-DD');
          },
          thClass: 'td-short4',
          tdClass: 'td-short4 vertical-center'
        },
        {
          key: 'actions',
          show: true,
          sortable: false,
          label: ''
        }
      ],
      filter: '',
      list: []
    };
  }
};
</script>

